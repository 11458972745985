import request from '@/utils/request.js';
/**
 * 隧道列表
 * @returns {Promise}
 */
export function getTunnelData(params) {
  return request({
    url: '/traffic-construction/tunnel/getTunnelData',
    method: 'get',
    params
  });
}
/* 隧道项目部切换下拉列表
 * @returns {Promise}
 */
export function getTunnelDepartment(params) {
  return request({
    url: '/traffic-construction/device/getTunnelCameraDepartment',
    method: 'get',
    params
  });
}
/* 隧道项目部摄像头
 * @returns {Promise}
 */
export function getTunnelCamera(params) {
  return request({
    url: '/traffic-construction/device/getTunnelCamera',
    method: 'get',
    params
  });
}
/* 应急物资列表
 * @returns {Promise}
 */
export function getScreenData(params) {
  return request({
    url: '/traffic-construction/emergencyFacilities/getScreenData',
    method: 'get',
    params
  });
}
/* 播放回调
 * @returns {Promise}
 */
export function toPlay(data) {
  return request({
    url: '/roses-platform/httpCallback/play',
    method: 'post',
    data
  });
}
/* 停止播放回调
 * @returns {Promise}
 */
export function toStop(data) {
  return request({
    url: '/roses-platform/httpCallback/stop',
    method: 'post',
    data
  });
}

